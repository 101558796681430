<template>
  <v-container>
    <SiteHeaderComponent header="Statistik" />
    <v-container
      class="d-flex flex-wrap justify-space-around justify-space-between mt-10"
    >
      <v-card
        :to="{ name: category.pathName }"
        v-for="category in categories"
        :key="category.name"
        class="mx-4 my-2 rounded-lg"
        width="300"
      >
        <v-img height="300" :src="category.image"></v-img>

        <v-card-title class="d-flex justify-center">
          {{ category.name }}
        </v-card-title>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import RevenueImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Umsaetze.svg'
//import OfferImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Auftrag.svg'
//import AddressImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Adressen.svg'
//import ArticleImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Artikel.svg'
import MetricImage from '@/assets/svg/lba-apps/lba-statistics/LBA-Statistik_Kennzahlen.svg'

export default {
  components: {
    SiteHeaderComponent
  },
  data() {
    return {
      categories: [
        {
          name: 'Umsatz',
          image: RevenueImage,
          pathName: 'statisticsRevenue'
        },
        /*
          {
            name: 'Aufträge',
            image: OfferImage,
            pathName: 'statisticsOffer'
          },
          {
            name: 'Kunden',
            image: AddressImage,
            pathName: 'statisticsCustomer'
          },
          {
            name: 'Artikel',
            image: ArticleImage,
            pathName: 'statisticsArticle'
          },*/
        {
          name: 'Kennzahlen',
          image: MetricImage,
          pathName: 'statisticsMetric'
        }
      ]
    }
  }
}
</script>

<style></style>
