<template>
  <v-container class="mt-8">
    <v-layout>
      <v-flex class="d-flex justify-center">
        <h1 class="header-header">
          {{ header }}
        </h1>
        <h4 class="text-center header-subheader">
          {{ subheader }}
        </h4>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true
    },
    subheader: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    navigateBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style></style>
