<template>
  <div>
    <StatisticsHomepage v-if="isStatisticsIndexSite" />

    <router-view></router-view>
  </div>
</template>

<script>
import StatisticsHomepage from '@/components/statistics/StatisticsHomepage.vue'

export default {
  components: {
    StatisticsHomepage
  },
  computed: {
    isStatisticsIndexSite() {
      if (this.$route.name == 'Statistik') return true

      return false
    }
  }
}
</script>

<style></style>
